<script setup>
const props = defineProps({
  color: {
    type: String,
    default: "#5E0000"
  }
})
</script>
<template>
  <svg class="inline-block" width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40.5732 55.92L35.7732 66.32L29.5332 59.6L20.4932 61.28L24.9732 47.04M39.6132 53.68L45.0532 66.4L50.8932 59.04L60.2532 61.04L55.2932 47.04M40.0932 21.44L43.5332 29.6L52.0932 30.16L45.3732 36L47.4532 44.32L39.8532 39.76L32.5732 44.32L34.5732 35.68L28.0132 30.24L36.8132 29.52L40.0932 21.44ZM40.3332 13.52C51.4532 13.52 60.4132 22.48 60.4132 33.6C60.4132 44.72 51.4532 53.68 40.3332 53.68C29.2132 53.68 20.2532 44.72 20.2532 33.6C20.2532 22.56 29.2132 13.52 40.3332 13.52Z" :stroke="props.color" stroke-width="2" stroke-miterlimit="22.9256" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
